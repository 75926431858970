define("api/models/target", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/target", "@ember-data/model", "@ember/service"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _target, _model, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPES_TARGET = _exports.TYPE_TARGET_SSH = _exports.TYPE_TARGET_TCP = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const TYPE_TARGET_TCP = 'tcp';
  _exports.TYPE_TARGET_TCP = TYPE_TARGET_TCP;
  const TYPE_TARGET_SSH = 'ssh';
  _exports.TYPE_TARGET_SSH = TYPE_TARGET_SSH;
  const TYPES_TARGET = Object.freeze([TYPE_TARGET_TCP, TYPE_TARGET_SSH]);
  _exports.TYPES_TARGET = TYPES_TARGET;
  let TargetModel = (_dec = (0, _model.attr)('host-source-id-array', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec2 = (0, _model.attr)('string-array', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec3 = (0, _model.attr)('string-array', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), (_class = class TargetModel extends _target.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "host_sources", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brokered_credential_source_ids", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "injected_application_credential_source_ids", _descriptor4, this);
    }

    /**
     * An array of resolved host set and host catalog instances.  Model instances
     * must already be loaded into the store (this method will not load unloaded
     * instances).  Unresolvable instances are excluded from the array.
     * @type {[{model: HostSetModel, hostCatalog: HostCatalogModel}]}
     */
    get hostSets() {
      return this.host_sources.map(_ref => {
        let {
          host_source_id,
          host_catalog_id
        } = _ref;
        return {
          model: this.store.peekRecord('host-set', host_source_id),
          hostCatalog: this.store.peekRecord('host-catalog', host_catalog_id)
        };
      }).filter(hostSetRef => hostSetRef.model !== null);
    }
    /**
     * An array of resolved credential library and credential instances.  Model instances
     * must already be loaded into the store (this method will not load unloaded
     * instances).  Unresolvable instances are excluded from the array.
     * @type {[CredentialLibraryModel, CredentialModel]}
     */


    get brokeredCredentialSources() {
      return this.brokered_credential_source_ids.map(source => {
        if (source.value.startsWith('cred')) {
          return this.store.peekRecord('credential', source.value);
        } else {
          return this.store.peekRecord('credential-library', source.value);
        }
      }).filter(Boolean);
    }
    /**
     * An array of resolved credential library and credential instances.  Model instances
     * must already be loaded into the store (this method will not load unloaded
     * instances).  Unresolvable instances are excluded from the array.
     * @type {[CredentialLibraryModel, CredentialModel]}
     */


    get injectedApplicationCredentialSources() {
      return this.injected_application_credential_source_ids.map(source => {
        if (source.value.startsWith('cred')) {
          return this.store.peekRecord('credential', source.value);
        } else {
          return this.store.peekRecord('credential-library', source.value);
        }
      }).filter(Boolean);
    }
    /**
     * Sessions associated with this target (but only already loaded sessions).
     * @type {SessionModel[]}
     */


    get sessions() {
      return this.store.peekAll('session').filter(s => s && s.target_id === this.id);
    }
    /**
     * The project associated with this target (if already loaded).
     * @type {ScopeModel}
     */


    get project() {
      return this.store.peekRecord('scope', this.scopeID);
    }
    /**
     * True if any sessions associated with this target are active or pending.
     * @type {boolean}
     */


    get isActive() {
      const pendingOrActiveSessions = this.sessions.filter(s => s.isActive || s.isPending);
      return Boolean(pendingOrActiveSessions.length);
    } // =methods

    /**
     * Adds host sets via the `add-host-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} hostSetIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addHostSources(hostSetIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'add-host-sources',
        hostSetIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete host sets via the `remove-host-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} hostSetIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeHostSources(hostSetIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'remove-host-sources',
        hostSetIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single host set via the `remove-host-sources` method.
     * @param {number} hostSetID
     * @param {object} options
     * @return {Promise}
     */


    removeHostSource(hostSetID, options) {
      return this.removeHostSources([hostSetID], options);
    }
    /**
     * Adds credential sources via the `add-credential-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} brokeredCredentialSourceIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addBrokeredCredentialSources(brokeredCredentialSourceIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'add-credential-sources',
        brokeredCredentialSourceIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Adds credential sources via the `add-credential-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} injectedApplicationCredentialSourceIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addInjectedApplicationCredentialSources(injectedApplicationCredentialSourceIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'add-credential-sources',
        injectedApplicationCredentialSourceIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete credential libraries and credentials via the `remove-credential-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} brokeredCredentialSourceIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeBrokeredCredentialSources(brokeredCredentialSourceIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'remove-credential-sources',
        brokeredCredentialSourceIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single credential library/credential set via the `remove-credential-sources` method.
     * @param {number} brokeredCredentialSourceID
     * @param {object} options
     * @return {Promise}
     */


    removeBrokeredCredentialSource(brokeredCredentialSourceID, options) {
      return this.removeBrokeredCredentialSources([brokeredCredentialSourceID], options);
    }
    /**
     * Delete credential libraries and credentials via the `remove-credential-sources` method.
     * See serializer and adapter for more information.
     * @param {[string]} injectedApplicationCredentialSourceIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removeInjectedApplicationCredentialSources(injectedApplicationCredentialSourceIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'remove-credential-sources',
        injectedApplicationCredentialSourceIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single credential library/credential set via the `remove-credential-sources` method.
     * @param {number} injectedApplicationCredentialSourceID
     * @param {object} options
     * @return {Promise}
     */


    removeInjectedApplicationCredentialSource(injectedApplicationCredentialSourceID, options) {
      return this.removeInjectedApplicationCredentialSources([injectedApplicationCredentialSourceID], options);
    }
    /**
     * True if the target type is tcp.
     * @type {boolean}
     */


    get isTCP() {
      return this.type === TYPE_TARGET_TCP;
    }
    /**
     * True if the target type is ssh.
     * @type {boolean}
     */


    get isSSH() {
      return this.type === TYPE_TARGET_SSH;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "host_sources", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brokered_credential_source_ids", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "injected_application_credential_source_ids", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TargetModel;
});