define("api/generated/models/auth-method", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47;

  /**
   *
   */
  let GeneratedAuthMethodModel = (_dec = (0, _model.attr)('boolean', {
    description: 'Specifies if this is the primary auth method for its scope.',
    readOnly: true
  }), _dec2 = (0, _model.attr)('string', {
    description: 'The type of the resource, to help differentiate schemas'
  }), _dec3 = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes'
  }), _dec4 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes'
  }), _dec5 = (0, _model.attr)('date', {
    description: 'The time this resource was created\nOutput only.',
    readOnly: true
  }), _dec6 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated\nOutput only.',
    readOnly: true
  }), _dec7 = (0, _model.attr)('boolean', {
    description: 'Whether the resource is disabled'
  }), _dec8 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), _dec9 = (0, _model.attr)('string', {
    for: ['oidc', 'ldap'],
    readOnly: true,
    isNestedAttribute: true
  }), _dec10 = (0, _model.attr)('string', {
    for: 'oidc',
    isNestedAttribute: true
  }), _dec11 = (0, _model.attr)('string', {
    for: 'oidc',
    isNestedAttribute: true
  }), _dec12 = (0, _model.attr)('string', {
    for: 'oidc',
    isNestedAttribute: true,
    isSecret: true
  }), _dec13 = (0, _model.attr)('string', {
    for: 'oidc',
    readOnly: true,
    isNestedAttribute: true
  }), _dec14 = (0, _model.attr)('number', {
    for: 'oidc',
    isNestedAttribute: true
  }), _dec15 = (0, _model.attr)('string', {
    for: 'oidc',
    isNestedAttribute: true
  }), _dec16 = (0, _model.attr)('string', {
    for: 'oidc',
    readOnly: true,
    isNestedAttribute: true
  }), _dec17 = (0, _model.attr)('boolean', {
    for: 'oidc',
    isNestedAttribute: true
  }), _dec18 = (0, _model.attr)('boolean', {
    for: 'oidc',
    isNestedAttribute: true
  }), _dec19 = (0, _model.attr)('string-array', {
    for: 'oidc',
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), _dec20 = (0, _model.attr)('string-array', {
    for: 'oidc',
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), _dec21 = (0, _model.attr)('string-array', {
    for: 'oidc',
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), _dec22 = (0, _model.attr)('string-array', {
    for: 'oidc',
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), _dec23 = (0, _model.attr)('account-value-map-array', {
    for: 'oidc',
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), _dec24 = (0, _model.attr)('boolean', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec25 = (0, _model.attr)('boolean', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec26 = (0, _model.attr)('boolean', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec27 = (0, _model.attr)('boolean', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec28 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec29 = (0, _model.attr)('string-array', {
    for: 'ldap',
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), _dec30 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec31 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec32 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec33 = (0, _model.attr)('boolean', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec34 = (0, _model.attr)('boolean', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec35 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec36 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec37 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec38 = (0, _model.attr)('string-array', {
    for: 'ldap',
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), _dec39 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec40 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true,
    isSecret: true
  }), _dec41 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true,
    readOnly: true
  }), _dec42 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec43 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true,
    isSecret: true
  }), _dec44 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true,
    readOnly: true
  }), _dec45 = (0, _model.attr)('account-value-map-array', {
    for: 'ldap',
    emptyArrayIfMissing: true,
    isNestedAttribute: true
  }), _dec46 = (0, _model.attr)('number', {
    for: 'ldap',
    isNestedAttribute: true
  }), _dec47 = (0, _model.attr)('string', {
    for: 'ldap',
    isNestedAttribute: true
  }), (_class = class GeneratedAuthMethodModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "is_primary", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "disabled", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "state", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "issuer", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "client_id", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "client_secret", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "client_secret_hmac", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "max_age", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "api_url_prefix", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "callback_url", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "disable_discovered_config_validation", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "dry_run", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "claims_scopes", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "signing_algorithms", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "allowed_audiences", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "idp_ca_certs", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "account_claim_maps", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "start_tls", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "insecure_tls", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "discover_dn", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "anon_group_search", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "upn_domain", _descriptor28, this);
      (0, _initializerDefineProperty2.default)(this, "urls", _descriptor29, this);
      (0, _initializerDefineProperty2.default)(this, "user_dn", _descriptor30, this);
      (0, _initializerDefineProperty2.default)(this, "user_attr", _descriptor31, this);
      (0, _initializerDefineProperty2.default)(this, "user_filter", _descriptor32, this);
      (0, _initializerDefineProperty2.default)(this, "enable_groups", _descriptor33, this);
      (0, _initializerDefineProperty2.default)(this, "use_token_groups", _descriptor34, this);
      (0, _initializerDefineProperty2.default)(this, "group_dn", _descriptor35, this);
      (0, _initializerDefineProperty2.default)(this, "group_attr", _descriptor36, this);
      (0, _initializerDefineProperty2.default)(this, "group_filter", _descriptor37, this);
      (0, _initializerDefineProperty2.default)(this, "certificates", _descriptor38, this);
      (0, _initializerDefineProperty2.default)(this, "client_certificate", _descriptor39, this);
      (0, _initializerDefineProperty2.default)(this, "client_certificate_key", _descriptor40, this);
      (0, _initializerDefineProperty2.default)(this, "client_certificate_key_hmac", _descriptor41, this);
      (0, _initializerDefineProperty2.default)(this, "bind_dn", _descriptor42, this);
      (0, _initializerDefineProperty2.default)(this, "bind_password", _descriptor43, this);
      (0, _initializerDefineProperty2.default)(this, "bind_password_hmac", _descriptor44, this);
      (0, _initializerDefineProperty2.default)(this, "account_attribute_maps", _descriptor45, this);
      (0, _initializerDefineProperty2.default)(this, "maximum_page_size", _descriptor46, this);
      (0, _initializerDefineProperty2.default)(this, "dereference_aliases", _descriptor47, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "is_primary", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disabled", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "state", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "issuer", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_id", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_secret", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_secret_hmac", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "max_age", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "api_url_prefix", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "callback_url", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disable_discovered_config_validation", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dry_run", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "claims_scopes", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "signing_algorithms", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "allowed_audiences", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "idp_ca_certs", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account_claim_maps", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "start_tls", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "insecure_tls", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "discover_dn", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "anon_group_search", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "upn_domain", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "urls", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "user_dn", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "user_attr", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "user_filter", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "enable_groups", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "use_token_groups", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "group_dn", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "group_attr", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "group_filter", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "certificates", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_certificate", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_certificate_key", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_certificate_key_hmac", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bind_dn", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bind_password", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bind_password_hmac", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account_attribute_maps", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "maximum_page_size", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dereference_aliases", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedAuthMethodModel;
});