define("api/models/role", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/role", "@ember-data/model", "@ember/service", "rsvp", "api/models/auth-method"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _role, _model, _service, _rsvp, _authMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let RoleModel = (_dec = (0, _model.attr)('principal-array', {
    readOnly: true,
    emptyArrayIfMissing: true
  }), _dec2 = (0, _model.attr)({
    readOnly: true,
    emptyArrayIfMissing: true
  }), (_class = class RoleModel extends _role.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "resourceFilterStore", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "principals", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "grant_strings", _descriptor4, this);
    }

    /**
     * Convenience for looking up the grant scope, if loaded.
     */
    get grantScope() {
      return this.grant_scope_id ? this.store.peekRecord('scope', this.grant_scope_id) : null;
    }
    /**
     * A list of IDs for principals of type `user`.
     * @type {string[]}
     */


    get userIDs() {
      return this.principals.filter(_ref => {
        let {
          type
        } = _ref;
        return type === 'user';
      }).map(_ref2 => {
        let {
          principal_id
        } = _ref2;
        return principal_id;
      });
    }
    /**
     * A list of IDs for principals of type `group`.
     * @type {string[]}
     */


    get groupIDs() {
      return this.principals.filter(_ref3 => {
        let {
          type
        } = _ref3;
        return type === 'group';
      }).map(_ref4 => {
        let {
          principal_id
        } = _ref4;
        return principal_id;
      });
    }
    /**
     * A list of IDs for principals of type `managed group`.
     * @type {string[]}
     */


    get managedGroupIDs() {
      return this.principals.filter(_ref5 => {
        let {
          type
        } = _ref5;
        return type === 'managed group';
      }).map(_ref6 => {
        let {
          principal_id
        } = _ref6;
        return principal_id;
      });
    }
    /**
     * A promise that resolves to an array of User instances.
     * When calling this getter, be sure to await resolution
     * before interacting with the results.
     * @type {Promise[UserModel]}
     */


    get users() {
      const ids = this.userIDs; // Role has prinicipal IDs,
      // return a promise which resolves model instances for those IDs

      if (ids?.length) {
        return this.resourceFilterStore.queryBy('user', {
          id: ids
        }, {
          scope_id: 'global',
          recursive: true
        }).then(models => models.map(model => model));
      } // No principal IDs,
      // return a promise resolving to an empty array


      return (0, _rsvp.resolve)([]);
    }
    /**
     * A promise that resolves to an array of Group instances.
     * When calling this getter, be sure to await resolution
     * before interacting with the results.
     * @type {Promise[GroupModel]}
     */


    get groups() {
      const ids = this.groupIDs; // Role has prinicipal IDs,
      // return a promise which resolves model instances for those IDs

      if (ids?.length) {
        return this.resourceFilterStore.queryBy('group', {
          id: ids
        }, {
          scope_id: 'global',
          recursive: true
        }).then(models => models.map(model => model));
      } // No principal IDs,
      // return a promise resolving to an empty array


      return (0, _rsvp.resolve)([]);
    }
    /**
     * A promise that resolves to an array of ManagedGroup instances.
     * When calling this getter, be sure to await resolution
     * before interacting with the results.
     * @type {Promise[ManagedGroupModel]}
     */


    get managedGroups() {
      const ids = this.managedGroupIDs; // Role has prinicipal IDs
      // return a promise which resolves model instances for those IDs

      if (ids?.length) {
        // Collect all auth methods.
        const authMethods = this.resourceFilterStore.queryBy('auth-method', {
          type: [_authMethod.TYPE_AUTH_METHOD_OIDC, _authMethod.TYPE_AUTH_METHOD_LDAP]
        }, {
          scope_id: 'global',
          recursive: true
        }); // For each auth method, query all managed groups with IDs
        // that match the role's manage group principal IDs.

        const managedGroups = authMethods.then(methods => (0, _rsvp.all)(methods.map(_ref7 => {
          let {
            id: auth_method_id
          } = _ref7;
          return this.resourceFilterStore.queryBy('managed-group', {
            id: ids
          }, {
            auth_method_id
          }).then(models => models.map(model => model));
        }))) // The result is an array of arrays of model instances (grouped by
        // auth methods), so these must be flattened.
        .then(grouped => grouped.flat());
        return managedGroups;
      } // No principal IDs,
      // return a promise resolving to an empty array


      return (0, _rsvp.resolve)([]);
    } // =methods

    /**
     * Saves grant strings on the role via the `set-grants` method.
     * See serializer and adapter for more information.
     * @param {[string]} grantStrings
     * @return {Promise}
     */


    saveGrantStrings(grantStrings) {
      return this.save({
        adapterOptions: {
          method: 'set-grants',
          grantStrings
        }
      });
    }
    /**
     * Adds principals via the `add-principals` method.
     * See serializer and adapter for more information.
     * @param {[string]} principalIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    addPrincipals(principalIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'add-principals',
        principalIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete principals via the `remove-principals` method.
     * See serializer and adapter for more information.
     * @param {[string]} principalIDs
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    removePrincipals(principalIDs) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'remove-principals',
        principalIDs
      }; // There is no "deep merge" in ES.

      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }
    /**
     * Delete a single principal via the `remove-principals` method.
     * @param {number} principalIDs
     * @param {object} options
     * @return {Promise}
     */


    removePrincipal(principalID, options) {
      return this.removePrincipals([principalID], options);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resourceFilterStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "principals", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "grant_strings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RoleModel;
});