define("api/models/credential-store", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/credential-store", "@ember/object/computed"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _credentialStore, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.types = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  const types = ['static', 'vault'];
  _exports.types = types;
  let CredentialStoreModel = (_dec = (0, _computed.equal)('type', 'vault'), _dec2 = (0, _computed.equal)('type', 'static'), (_class = class CredentialStoreModel extends _credentialStore.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "isVault", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isStatic", _descriptor2, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isVault", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isStatic", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CredentialStoreModel;
});