define("api/generated/models/credential-store", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  /**
   * A credential store is a resource that can retrieve, store, and potentially
   * generate credentials.
   */
  let GeneratedCredentialStoreModel = (_dec = (0, _model.attr)('string', {
    description: 'The type of the resource, to help differentiate schemas'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes'
  }), _dec3 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes'
  }), _dec4 = (0, _model.attr)('date', {
    description: 'The time this resource was created\nOutput only.',
    readOnly: true
  }), _dec5 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated\nOutput only.',
    readOnly: true
  }), _dec6 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), _dec7 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: "The address to Vault server. This should be a complete URL such as 'https://127.0.0.1:8200'"
  }), _dec8 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: 'The namespace within Vault to use.'
  }), _dec9 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: "A PEM-encoded CA certificate to verify the Vault server's TLS certificate."
  }), _dec10 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: 'Name to use as the SNI host when connecting to Vault via TLS.'
  }), _dec11 = (0, _model.attr)('boolean', {
    for: 'vault',
    isNestedAttribute: true,
    description: 'Whether or not to skip TLS verification.'
  }), _dec12 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    isSecret: true,
    description: 'A token used for accessing Vault.'
  }), _dec13 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: 'The Vault token hmac.',
    readOnly: true
  }), _dec14 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: 'A PEM-encoded client certificate to use for TLS authentication to the Vault server.'
  }), _dec15 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: "A PEM-encoded private key matching the client certificate from 'client_certificate'."
  }), _dec16 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: 'The Vault client certificate key hmac.',
    readOnly: true
  }), _dec17 = (0, _model.attr)('string', {
    for: 'vault',
    isNestedAttribute: true,
    description: 'Filters to the worker(s) who can handle Vault requests for this cred store.'
  }), (_class = class GeneratedCredentialStoreModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "address", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "namespace", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "ca_cert", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "tls_server_name", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "tls_skip_verify", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "token", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "token_hmac", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "client_certificate", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "client_certificate_key", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "client_certificate_key_hmac", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "worker_filter", _descriptor17, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "address", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "namespace", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ca_cert", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tls_server_name", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tls_skip_verify", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "token", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "token_hmac", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_certificate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_certificate_key", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client_certificate_key_hmac", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "worker_filter", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedCredentialStoreModel;
});