define("api/abilities/role", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@babel/runtime/helpers/esm/defineProperty", "api/abilities/model", "@ember/service"], function (_exports, _initializerDefineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _defineProperty2, _model, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  class InvalidRolePrincipalTypeError extends Error {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "name", 'InvalidRolePrincipalTypeError');
    }

  }
  /**
   * Provides abilities for roles.
   */


  let RoleAbility = (_class = class RoleAbility extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "can", _descriptor, this);
    }

    // =permissions

    /**
     * @type {boolean}
     */
    get canSetGrants() {
      return this.hasAuthorizedAction('set-grants');
    }
    /**
     * @type {boolean}
     */


    get canAddPrincipals() {
      return this.hasAuthorizedAction('add-principals');
    }
    /**
     * @type {boolean}
     */


    get canRemovePrincipals() {
      return this.hasAuthorizedAction('remove-principals');
    }
    /**
     * Returns the ability check for a principal model instance.
     * Valid role principal models are: User, Group, and ManagedGroup.
     * Throws an error if the principal type is invalid.
     * @type {boolean}
     * @throws {InvalidRolePrincipalTypeError}
     */


    get canReadPrincipal() {
      const type = this.model.constructor.modelName;
      const typeIsValid = type === 'user' || type === 'group' || type === 'managed-group';
      if (!typeIsValid) throw new InvalidRolePrincipalTypeError(`Expected a role principal of type 'user', 'group', or 'managed-group'.  Got '${type}'.`);
      return this.can.can(`read ${type}`, this.model);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "can", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = RoleAbility;
});