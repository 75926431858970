define("api/generated/models/credential", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  /**
   * A credential is a data structure containing one or more secrets that binds an
   * identity to a set of permissions or capabilities on a host for a session.
   */
  let GeneratedCredentialModel = (_dec = (0, _model.attr)('string', {
    description: 'The ID of the Credential Store of which this Credential is a part.'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'The type of the resource, to help differentiate schemas'
  }), _dec3 = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes'
  }), _dec4 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes'
  }), _dec5 = (0, _model.attr)('date', {
    description: 'The time this resource was created\nOutput only.',
    readOnly: true
  }), _dec6 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated\nOutput only.',
    readOnly: true
  }), _dec7 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), _dec8 = (0, _model.attr)('string', {
    for: ['username_password', 'ssh_private_key'],
    isNestedAttribute: true,
    description: 'The username for credential.'
  }), _dec9 = (0, _model.attr)('string', {
    for: 'username_password',
    isNestedAttribute: true,
    isSecret: true,
    description: 'The password for credential.'
  }), _dec10 = (0, _model.attr)('string', {
    for: 'ssh_private_key',
    isNestedAttribute: true,
    isSecret: true,
    description: 'The private key for credential.'
  }), _dec11 = (0, _model.attr)('string', {
    for: 'ssh_private_key',
    isNestedAttribute: true,
    isSecret: true,
    description: 'The private key passphrase for credential.'
  }), _dec12 = (0, _model.attr)('string', {
    for: 'json',
    isNestedAttribute: true,
    isSecret: true,
    description: 'The secret associated with credential.'
  }), (_class = class GeneratedCredentialModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "credential_store_id", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "username", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "password", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "private_key", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "private_key_passphrase", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "json_object", _descriptor12, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "credential_store_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "username", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "password", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "private_key", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "private_key_passphrase", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "json_object", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedCredentialModel;
});