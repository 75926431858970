define("api/generated/models/storage-bucket", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  /**
   * A storage bucket is a resource that that represents a bucket in an external object store.
   */
  let GeneratedStorageBucketModel = (_dec = (0, _model.attr)('string', {
    description: 'The type of the resource, to help differentiate schemas'
  }), _dec2 = (0, _model.attr)({
    description: 'Plugin information for this resource.',
    readOnly: true
  }), _dec3 = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes.'
  }), _dec4 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes.'
  }), _dec5 = (0, _model.attr)('date', {
    description: 'The time this resource was created\nOutput only.',
    readOnly: true
  }), _dec6 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated\nOutput only.',
    readOnly: true
  }), _dec7 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), _dec8 = (0, _model.attr)('string', {
    description: 'The name of the bucket in the external object store.'
  }), _dec9 = (0, _model.attr)('string', {
    description: 'The base path where session recordings will be stored in the external object store.'
  }), _dec10 = (0, _model.attr)('string', {
    description: 'An expression used to filter the workers that have network access to a service that is hosting the external object store.'
  }), _dec11 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: 'The AWS region to use.'
  }), _dec12 = (0, _model.attr)('string', {
    readOnly: true,
    isNestedAttribute: true
  }), _dec13 = (0, _model.attr)('boolean', {
    isNestedAttribute: true,
    description: 'If set to `true`, credential rotation is not performed.'
  }), _dec14 = (0, _model.attr)('string', {
    isNestedSecret: true,
    description: 'The AWS access key ID to use.'
  }), _dec15 = (0, _model.attr)('string', {
    isNestedSecret: true,
    description: 'The secret access key to use.'
  }), _dec16 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: 'The role ARN to use.'
  }), _dec17 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: 'The role external ID to use.'
  }), _dec18 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: 'The role session to use.'
  }), _dec19 = (0, _model.attr)('object-as-array', {
    isNestedAttribute: true,
    description: 'The role tags to use.'
  }), (_class = class GeneratedStorageBucketModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "plugin", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "bucket_name", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "bucket_prefix", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "worker_filter", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "region", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "secrets_hmac", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "disable_credential_rotation", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "access_key_id", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "secret_access_key", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "role_arn", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "role_external_id", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "role_session_name", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "role_tags", _descriptor19, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "plugin", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bucket_name", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bucket_prefix", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "worker_filter", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "region", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "secrets_hmac", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "disable_credential_rotation", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access_key_id", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "secret_access_key", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "role_arn", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "role_external_id", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "role_session_name", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "role_tags", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedStorageBucketModel;
});