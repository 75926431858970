define("api/models/connection-recording", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/connection-recording", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _connectionRecording, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let ConnectionRecordingModel = (_dec = (0, _model.belongsTo)('session-recording', {
    async: false,
    inverse: 'connection_recordings'
  }), _dec2 = (0, _model.hasMany)('channel-recording', {
    async: false,
    inverse: 'connection_recording'
  }), (_class = class ConnectionRecordingModel extends _connectionRecording.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session_recording", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "channel_recordings", _descriptor2, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session_recording", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "channel_recordings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ConnectionRecordingModel;
});