define("api/generated/models/credential-library", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  /**
   * A credential library is a resource that can generate and retrieve
   * credentials.
   */
  let GeneratedCredentialLibraryModel = (_dec = (0, _model.attr)('string', {
    description: 'The type of the resource, to help differentiate schemas'
  }), _dec2 = (0, _model.attr)('string', {
    description: 'The owning credential store ID.'
  }), _dec3 = (0, _model.attr)('string', {
    description: 'Optional name for identification purposes'
  }), _dec4 = (0, _model.attr)('string', {
    description: 'Optional user-set description for identification purposes'
  }), _dec5 = (0, _model.attr)('date', {
    description: 'The time this resource was created\nOutput only.',
    readOnly: true
  }), _dec6 = (0, _model.attr)('date', {
    description: 'The time this resource was last updated\nOutput only.',
    readOnly: true
  }), _dec7 = (0, _model.attr)('number', {
    description: 'Current version number of this resource.'
  }), _dec8 = (0, _model.attr)('string', {
    for: 'vault-generic',
    isNestedAttribute: true,
    description: 'The HTTP method the library uses when requesting credentials from Vault.',
    defaultValue: () => 'GET'
  }), _dec9 = (0, _model.attr)('string', {
    for: 'vault-generic',
    isNestedAttribute: true,
    description: 'The body of the HTTP request the library sends to Vault when requesting credentials. Only valid if `http_method` is set to `POST`.'
  }), _dec10 = (0, _model.attr)('string', {
    isNestedAttribute: true,
    description: 'The path in Vault to request credentials from.'
  }), _dec11 = (0, _model.attr)('string', {
    for: 'vault-ssh-certificate',
    isNestedAttribute: true,
    description: 'The username to use when making an SSH connection.'
  }), _dec12 = (0, _model.attr)('string', {
    for: 'vault-ssh-certificate',
    isNestedAttribute: true,
    description: 'The desired key type to use when generating a private key.'
  }), _dec13 = (0, _model.attr)('number', {
    for: 'vault-ssh-certificate',
    isNestedAttribute: true,
    description: 'Specifies the number of bits used when generating the private key. Not used if key type is ed25519'
  }), _dec14 = (0, _model.attr)('string', {
    for: 'vault-ssh-certificate',
    isNestedAttribute: true,
    description: 'Specifies the requested time to live for the certificate.'
  }), _dec15 = (0, _model.attr)('string', {
    for: 'vault-ssh-certificate',
    isNestedAttribute: true,
    description: 'Specifies the key id that the created certificate should have.'
  }), _dec16 = (0, _model.attr)('object-as-array', {
    for: 'vault-ssh-certificate',
    isNestedAttribute: true,
    description: 'Specifies a map of the critical options that the certificate should be signed for.'
  }), _dec17 = (0, _model.attr)('object-as-array', {
    for: 'vault-ssh-certificate',
    isNestedAttribute: true,
    description: 'Specifies a map of the extensions that the certificate should be signed for.'
  }), (_class = class GeneratedCredentialLibraryModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "credential_store_id", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "version", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "http_method", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "http_request_body", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "path", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "username", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "key_type", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "key_bits", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "ttl", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "key_id", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "critical_options", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "extensions", _descriptor17, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "credential_store_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "version", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "http_method", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "http_request_body", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "path", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "username", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "key_type", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "key_bits", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ttl", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "key_id", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "critical_options", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "extensions", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedCredentialLibraryModel;
});