define("api/models/session-recording", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/session-recording", "@ember-data/model", "@ember/service"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _sessionRecording, _model, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_SESSION_RECORDING_UNKNOWN = _exports.STATE_SESSION_RECORDING_AVAILABLE = _exports.STATE_SESSION_RECORDING_STARTED = _exports.TYPES_SESSION_RECORDING = _exports.TYPE_SESSION_RECORDING_SSH = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  const TYPE_SESSION_RECORDING_SSH = 'ssh';
  _exports.TYPE_SESSION_RECORDING_SSH = TYPE_SESSION_RECORDING_SSH;
  const TYPES_SESSION_RECORDING = Object.freeze([TYPE_SESSION_RECORDING_SSH]);
  _exports.TYPES_SESSION_RECORDING = TYPES_SESSION_RECORDING;
  const STATE_SESSION_RECORDING_STARTED = 'started';
  _exports.STATE_SESSION_RECORDING_STARTED = STATE_SESSION_RECORDING_STARTED;
  const STATE_SESSION_RECORDING_AVAILABLE = 'available';
  _exports.STATE_SESSION_RECORDING_AVAILABLE = STATE_SESSION_RECORDING_AVAILABLE;
  const STATE_SESSION_RECORDING_UNKNOWN = 'unknown';
  _exports.STATE_SESSION_RECORDING_UNKNOWN = STATE_SESSION_RECORDING_UNKNOWN;
  let SessionRecordingModel = (_dec = (0, _model.hasMany)('connection-recording', {
    async: false,
    inverse: 'session_recording'
  }), (_class = class SessionRecordingModel extends _sessionRecording.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "connection_recordings", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
    }

    /**
     * True if the session recording type is `ssh`.
     * @type {boolean}
     */
    get isSSH() {
      return this.type === TYPE_SESSION_RECORDING_SSH;
    }
    /**
     * True if the session recording is an unknown type.
     * @type {boolean}
     */


    get isUnknown() {
      return !TYPES_SESSION_RECORDING.includes(this.type);
    }
    /**
     * True if the session recording is in an available state.
     * @type {boolean}
     */


    get isAvailable() {
      return this.state === STATE_SESSION_RECORDING_AVAILABLE;
    }
    /**
     * Returns scope name or scope id from the target that belongs to the session recording.
     * @type {string}
     */


    get targetScopeDisplayName() {
      return this.create_time_values?.target?.scope?.name || this.create_time_values?.target?.scope?.id;
    }
    /**
     * Returns the user name or user id that belongs to the session recording.
     * @type {string}
     */


    get userDisplayName() {
      return this.create_time_values?.user?.name || this.create_time_values?.user?.id;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "connection_recordings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SessionRecordingModel;
});