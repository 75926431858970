define("api/abilities/credential-store", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/abilities/model", "@ember/service"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  /**
   * Provides abilities for credential store.
   */
  let CredentialStoreAbility = (_class = class CredentialStoreAbility extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "features", _descriptor, this);
    }

    // =permissions

    /**
     * Only "known" credential store types may be read.
     * @type {boolean}
     */
    get canRead() {
      return this.features.isEnabled('static-credentials') ? this.hasAuthorizedAction('read') : !this.model.isStatic && this.hasAuthorizedAction('read');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "features", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = CredentialStoreAbility;
});