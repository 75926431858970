define("api/models/channel-recording", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/channel-recording", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _channelRecording, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MIME_TYPE_ASCIICAST = void 0;

  var _dec, _class, _descriptor;

  const MIME_TYPE_ASCIICAST = 'application/x-asciicast';
  _exports.MIME_TYPE_ASCIICAST = MIME_TYPE_ASCIICAST;
  let ChannelRecordingModel = (_dec = (0, _model.belongsTo)('connection-recording', {
    async: false,
    inverse: 'channel_recordings'
  }), (_class = class ChannelRecordingModel extends _channelRecording.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "connection_recording", _descriptor, this);
    }

    /**
     * True if the channel supports asciicast.
     * @type {boolean}
     */
    get isAsciicast() {
      return this.mime_types?.includes(MIME_TYPE_ASCIICAST) ?? false;
    }
    /**
     * Download the ascii cast recording for this channel.
     * @returns {Promise<string>}
     */


    getAsciicast() {
      const adapter = this.store.adapterFor('session-recording');
      return adapter.getAsciicast(this.id);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "connection_recording", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelRecordingModel;
});