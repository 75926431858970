define("api/generated/models/session-recording", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  /**
   * A session recording contains all data related to session recordings
   */
  let GeneratedSessionRecordingModel = (_dec = (0, _model.attr)('string', {
    description: 'The type of the resource, to help differentiate schemas.',
    readOnly: true
  }), _dec2 = (0, _model.attr)('date', {
    description: 'The time the Session Recording was created in the controller.',
    readOnly: true
  }), _dec3 = (0, _model.attr)('date', {
    description: 'The time of the most recent update to the Session Recording.',
    readOnly: true
  }), _dec4 = (0, _model.attr)({
    description: 'Contains the values of related fields at the time this Session Recording was created',
    readOnly: true
  }), _dec5 = (0, _model.attr)('string', {
    description: 'The ID of the Session which this Session Recording recorded.',
    readOnly: true
  }), _dec6 = (0, _model.attr)('string', {
    description: 'The ID of the Storage Bucket for the Target of this Session Recording',
    readOnly: true
  }), _dec7 = (0, _model.attr)('string', {
    description: 'The total number of bytes uploaded from the client in the Session.',
    readOnly: true
  }), _dec8 = (0, _model.attr)('string', {
    description: 'The total number of bytes downloaded to the client in the Session.',
    readOnly: true
  }), _dec9 = (0, _model.attr)('number', {
    description: 'The total number of errors that occurred during the use of the Session.',
    readOnly: true
  }), _dec10 = (0, _model.attr)('date', {
    description: 'The time the Session started.',
    readOnly: true
  }), _dec11 = (0, _model.attr)('date', {
    description: 'The time the Session finished.',
    readOnly: true
  }), _dec12 = (0, _model.attr)({
    description: 'The array of mime types that this recording will support.',
    readOnly: true
  }), _dec13 = (0, _model.attr)('duration', {
    description: 'The duration of this session recording in ms.',
    readOnly: true
  }), _dec14 = (0, _model.attr)('string', {
    description: 'The current state of the session recording. One of "started", "available" and "unknown".',
    readOnly: true
  }), _dec15 = (0, _model.attr)('string', {
    description: 'Any error seen during the closing of the session recording. Currently only set if state is "unknown".',
    readOnly: true
  }), (_class = class GeneratedSessionRecordingModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "type", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "created_time", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "updated_time", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "create_time_values", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "session_id", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "storage_bucket_id", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "bytes_up", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "bytes_down", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "start_time", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "end_time", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "mime_types", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "duration", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "state", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "error_details", _descriptor15, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "created_time", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updated_time", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "create_time_values", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session_id", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "storage_bucket_id", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bytes_up", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bytes_down", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "start_time", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "end_time", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mime_types", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "duration", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "state", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "error_details", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedSessionRecordingModel;
});