define("api/abilities/channel-recording", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/abilities/model", "@ember/service"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  /**
   * Provides abilities for channel recordings.
   */
  let ChannelRecordingAbility = (_class = class ChannelRecordingAbility extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "abilities", _descriptor, this);
    }

    // =permissions
    get canGetAsciicast() {
      const sessionRecording = this.model.connection_recording.session_recording;
      const sessionRecordingAbility = this.abilities.abilityFor('session-recording', sessionRecording);
      return this.model.isAsciicast && sessionRecordingAbility.canDownload;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ChannelRecordingAbility;
});