define("api/generated/models/channel-recording", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/models/base", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  /**
   * A channel contains data for a single channel within a connection.
   */
  let GeneratedChannelRecordingModel = (_dec = (0, _model.attr)('string', {
    description: 'The total number of bytes uploaded from the client in this Channel.',
    readOnly: true
  }), _dec2 = (0, _model.attr)('string', {
    description: 'The total number of bytes downloaded to the client in this Channel.',
    readOnly: true
  }), _dec3 = (0, _model.attr)('number', {
    description: 'The total number of errors that occurred during the use of this Channel.',
    readOnly: true
  }), _dec4 = (0, _model.attr)('date', {
    description: 'The time the Channel started.',
    readOnly: true
  }), _dec5 = (0, _model.attr)('date', {
    description: 'The time the Channel finished.',
    readOnly: true
  }), _dec6 = (0, _model.attr)({
    description: 'The array of mime types that this recording will support.',
    readOnly: true
  }), _dec7 = (0, _model.attr)('duration', {
    description: 'The duration of this channel recording in ms.',
    readOnly: true
  }), (_class = class GeneratedChannelRecordingModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "bytes_up", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "bytes_down", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "start_time", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "end_time", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "mime_types", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "duration", _descriptor7, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bytes_up", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bytes_down", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "start_time", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "end_time", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mime_types", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "duration", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GeneratedChannelRecordingModel;
});