define("api/models/worker", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/worker", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _worker, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let WorkerModel = (_dec = (0, _model.attr)({
    description: 'The deduplicated union of the tags reported by the worker ' + 'from its configuration and any tags added through other means.\nOutput only.',
    readOnly: true
  }), _dec2 = (0, _model.attr)({
    description: "The tags set in the worker's configuration file.\nOutput only.",
    readOnly: true
  }), (_class = class WorkerModel extends _worker.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "canonical_tags", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "config_tags", _descriptor2, this);
    }

    // =attributes

    /**
     * Names are optional on models in our API.  Thus we need to fallback on ID
     * for display purposes.
     * @type {string}
     */
    get displayName() {
      return this.name || this.id;
    }
    /**
     * Returns whether the worker is a pki worker.
     * @type {boolean}
     */


    get isPki() {
      return this.type === 'pki';
    }
    /**
     * Returns the number of config tags present on the worker.
     * @type {number}
     */


    get tagCount() {
      if (!this.config_tags) {
        return 0;
      }

      return Object.values(this.config_tags).reduce((previousCount, currentTags) => previousCount + currentTags.length, 0);
    }
    /**
     * Returns the config tags as an array of key/value pair objects.
     * @type {[object]}
     */


    getConfigTagList() {
      if (!this.config_tags) {
        return null;
      }

      return Object.entries(this.config_tags).flatMap(_ref => {
        let [key, value] = _ref;
        return value.map(tag => ({
          key,
          value: tag
        }));
      });
    }

    /**
     * Method to modify the adapter to handle custom POST route for creating worker.
     * @param {string} workerGeneratedAuthToken
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */
    createWorkerLed(workerGeneratedAuthToken) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'create:worker-led',
        workerGeneratedAuthToken
      };
      return super.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canonical_tags", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "config_tags", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WorkerModel;
});